<template>
  <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="tpLink/alarmList" @clear="clearDateRange">
    <template slot="adSearch">
      <vm-search :label="$t('common.filter')">
        <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
      </vm-search>
      <vm-search :label="$t('event.alarmTime')">
        <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
          :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
          :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleChange">
        </el-date-picker>
      </vm-search>
    </template>
    <el-table-column prop="elevatorNo" :label="$t('event.elevatorNo')" width="125" align="center"></el-table-column>
    <el-table-column prop="elevatorName" :label="$t('event.elevatorName')"></el-table-column>
    <el-table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="130px" align="center"></el-table-column>
    <el-table-column prop="nodeCode" :label="$t('event.node')" width="90px" align="center"></el-table-column>
    <el-table-column prop="useUnitName" :label="$t('event.useUnit')"></el-table-column>
    <el-table-column :label="$t('event.picture')" align="center">
      <template v-slot="scope">
        <el-image v-if="scope.row.imageUrl" style="width: 40px; height: 40px" :src="scope.row.imageUrl"
          :preview-src-list="[scope.row.imageUrl]">
        </el-image>
      </template>
    </el-table-column>
    <el-table-column prop="alarmType" :label="$t('event.alarmType')" width="200px" align="center"></el-table-column>
    <el-table-column prop="alarmTime" :label="$t('event.alarmTime')" width="200px" align="center"></el-table-column>
  </vm-table>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      loading: false,
      dateRange: [],
      search: {
        filter: "",
        devId: "",
        elevatorId: "",
        startTime: "",
        endTime: "",
        isListPage: 1,
        alarmType: "电瓶车检测报警",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
<style lang="scss">
.el-picker-panel__footer .el-picker-panel__link-btn.el-button--text {
  display: none;
}
</style>
