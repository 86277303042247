<template>
  <div>
    <vm-table ref="vmTable" url="mbs" :filter.sync="search" :loading="loading" @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('event.dtuNumber')">
          <el-input v-model.trim="search.dtuCode" :placeholder="$t('event.dtuNumber')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('event.occurrenceTime')">
          <el-date-picker v-model="createTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
            :unlink-panels="true" :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']" @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button :loading="exportLoading" class="greenBtn" @click="exportExcel">
          {{ $t("event.export") }}
        </el-button>
      </template>
      <el-table-column prop="elevatorNo" :label="$t('event.elevatorNo')" width="125" align="center"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('event.elevatorName')"></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('event.dtuNumber')" width="130px" align="center"></el-table-column>
      <el-table-column prop="nodeCode" :label="$t('event.node')" width="90px" align="center"></el-table-column>
      <el-table-column prop="useUnit" :label="$t('event.useUnit')"></el-table-column>
      <el-table-column :label="$t('event.gatewayPowerDown')" align="center" :width="$l('180px', '100px')">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.powerLoss">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="false" :label="$t('event.nonGateAreaStop')" align="center" :width="$l('170px', '100px')">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.nonDoorZoneStop">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('event.alarmBell')" align="center" width="100px">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.bellAlarm">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('event.enterStopService')" align="center" :width="$l('160px', '110px')">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.enterStopMode">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="false" :label="$t('event.gatewayPowerFailure')" :width="$l('190px', '100px')"
        align="center">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.mainPowerLoss">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('event.repair')" align="center" width="80px">
        <template v-slot="scope">
          <span>
            <el-tag type="success" v-if="scope.row.repair">{{ $t("common.yes") }}</el-tag>
            <el-tag type="info" v-else>{{ $t("common.no") }}</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="$t('event.occurrenceTime')" width="160px"
        align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      exportLoading: false,
      loading: false,
      createTime: [],
      search: {
        filter: "",
        beginTime: "",
        endTime: "",
        dtuCode: "",
      },
    };
  },
  mounted() {
    this.getDate();
    this.getList(1);
  },
  methods: {
    getList(pageIndex) {
      this.loading = true;
      this.$refs.vmTable.getList(pageIndex);
      this.loading = false;
    },

    restTime() {
      this.createTime = [];
      this.search.beginTime = this.aogTime;
      this.search.endTime = this.nowTime;
      this.createTime.push(this.aogTime, this.nowTime);
    },
    getDate() {
      let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
      let ago = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 15, 0, 0, 0);
      this.nowTime = this.getTime(now);
      this.aogTime = this.getTime(ago);
      this.createTime.push(this.aogTime, this.nowTime);
      this.search.beginTime = this.aogTime;
      this.search.endTime = this.nowTime;
    },
    getTime(now) {
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth(); //得到月份
      var date = now.getDate(); //得到日期
      month = month + 1;
      month = month.toString().padStart(2, "0");
      date = date.toString().padStart(2, "0");

      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      hour = hour.toString().padStart(2, "0");
      minute = minute.toString().padStart(2, "0");
      second = second.toString().padStart(2, "0");

      let yearTime = `${year}-${month}-${date}`;
      let hourTime = `${hour}:${minute}:${second}`;

      return yearTime + " " + hourTime;
    },

    exportExcel() {
      if (this.createTime.length === 0) {
        this.$message.error(this.$t("event.tip.fillThreeDays"));
        return;
      }
      let fullTime = [];
      for (let i = 0; i < this.createTime.length; i++) {
        let time = this.createTime[i].split(" ");

        let fullYear = time[0].split("-");
        let year = fullYear[0];
        let month = fullYear[1];
        let day = fullYear[2];

        let fullDay = time[1].split(":");
        let hour = fullDay[0];
        let minute = fullDay[1];
        let second = fullDay[2];

        let date = new Date(year, month, day, hour, minute, second);
        fullTime.push(date.getTime());
      }
      if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 3) {
        this.$message.error(this.$t("event.tip.fillThreeDays"));
        return;
      }
      this.exportLoading = true;
      let params = {
        beginTime: this.createTime[0],
        endTime: this.createTime[1],
        filter: this.search.filter,
      };
      this.$api
        .exportFile("mbs/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = "";
          if (this.$i18n.isCn) {
            fileName = "MB记录.xlsx";
          } else {
            fileName = "MbEvent Info.xlsx";
          }
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.beginTime = dateRange[0];
        this.search.endTime = dateRange[1];
        if (dateRange.length > 0) {
          let fullTime = [];
          for (let i = 0; i < dateRange.length; i++) {
            let time = dateRange[i].split(" ");

            let fullYear = time[0].split("-");
            let year = fullYear[0];
            let month = fullYear[1];
            let day = fullYear[2];

            let fullDay = time[1].split(":");
            let hour = fullDay[0];
            let minute = fullDay[1];
            let second = fullDay[2];

            let date = new Date(year, month, day, hour, minute, second);
            fullTime.push(date.getTime());
          }
          if (Math.ceil((fullTime[1] - fullTime[0]) / (24 * 3600 * 1000)) > 15) {
            this.$message.error(this.$t("event.tip.fillFifteenDays"));
            this.createTime = [this.aogTime, this.nowTime];
            this.search.beginTime = this.aogTime;
            this.search.endTime = this.nowTime;
          }
        }
      } else {
        this.search.beginTime = this.aogTime;
        this.search.endTime = this.nowTime;
      }
    },
    clearDateRange() {
      this.createTime = [];
      this.search.beginTime = this.aogTime;
      this.search.endTime = this.nowTime;
      this.createTime.push(this.aogTime, this.nowTime);
    },
  },
};
</script>
<style lang="scss" scoped></style>
