<template>
  <div>
    <vm-table
      ref="vmTable"
      url="mbs/nb"
      :filter.sync="search"
      :loading="loading"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('elevator.timeOfOccurrence','发生时间')">
          <el-date-picker
            v-model="createTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :unlink-panels="true"
            :range-separator="$t('common.to')"
            :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')"
            :default-time="['00:00:00', '23:59:59']"
            @change="handleDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="125" align="center"></el-table-column>
      <el-table-column prop="elevatorName" :label="$t('elevator.name')"></el-table-column>
      <el-table-column prop="dtuCode" :label="$t('elevator.dtuCode')" width="130px" align="center"></el-table-column>
      <el-table-column
        prop="nodeCode"
        :label="$t('elevator.nodeCode')"
        :width="$l('110px','90px')"
        align="center"></el-table-column>
      <el-table-column prop="useUnit" :label="$t('elevator.useUnit')"></el-table-column>
      <el-table-column label="事件" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.eventCode === '1'">启动和投入使用</span>
          <span v-if="scope.row.eventCode === '2'">进入检修控制模式</span>
          <span v-if="scope.row.eventCode === '3'">主开关断电</span>
          <span v-if="scope.row.eventCode === '4'">手动操作紧急停止开关触发</span>
          <span v-if="scope.row.eventCode === '5'">检修盖板和楼层板开关动作</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="$l('elevator.timeOfOccurrence','发生时间')"
        width="160px"
        align="center"></el-table-column>
    </vm-table>
  </div>
</template>
<script>
  export default {
    components: {},
    data() {
      return {
        exportLoading: false,
        loading: false,
        createTime: [],
        search: {
          filter: "",
          beginTime: "",
          endTime: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.beginTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.beginTime = "";
          this.search.endTime = "";
        }
      },
      clearDateRange() {
        this.createTime = [];
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
